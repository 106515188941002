<script setup lang="ts">
import { ref, onMounted } from "vue";
import { Header, AppData } from "ram-components";
import SecureLS from "secure-ls";

import Greeting from "../components/Greeting.vue";
import Apps from "../components/Apps.vue";
import Footer from "../components/Footer.vue";

import { useAuthStore } from "../stores/authStore";

import moreAppsSvg from "../assets/images/moreApps.svg";

const authStore = useAuthStore();

const ls = new SecureLS({
  encodingType: "rc4",
  isCompression: false,
  encryptionSecret: import.meta.env.RAM_STORAGE_SECRET,
});

const recentApps = ref([] as Array<AppData>);
const favoriteApps = ref([] as Array<AppData>);

onMounted(() => {
  if (!authStore.isAuthenticated) {
    authStore.login();
  }

  if (!authStore.isMFACompleted) {
    authStore.refreshLogin();
  }
});

function handleInit(data: any) {
  // Code for Backwards compatibility, remove once we no longer use Vue2 Apps
  const { businessAreas } = data;
  const user = JSON.parse(ls.get("user"));
  user.IsInternalUser = businessAreas.some((businessArea: any) =>
    businessArea.CompanyName.startsWith("RAM ")
  );
  user.BusinessAreas = businessAreas;
  ls.set("user", JSON.stringify(user));
}

function handleAppsUpdate(data: any) {
  recentApps.value = data.recent;
  favoriteApps.value = data.favorites;
}
</script>

<template>
  <div class="flex h-full w-full flex-col" v-if="authStore.isAuthenticated">
    <Header selfAuth @init="handleInit" @apps="handleAppsUpdate" />
    <div class="flex grow flex-col space-y-5 overflow-y-auto p-8">
      <Greeting />
      <Apps :recent="recentApps" :favorites="favoriteApps" />
      <div class="grow" v-if="recentApps.length || favoriteApps.length"></div>
      <div
        class="flex items-center justify-center"
        v-if="recentApps.length || favoriteApps.length"
      >
        <img :src="moreAppsSvg" alt="More Apps" class="h-32" />
      </div>
      <Footer />
    </div>
  </div>
</template>
