import { createApp } from "vue";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import posthog from "posthog-js";
import { createPinia } from "pinia";
import router from "./router";
import App from "./App.vue";
import "./tailwind.css";

Bugsnag.start({
  apiKey: import.meta.env.BGSNG_API_KEY,
  appVersion: import.meta.env.RAM_APP_VERSION,
  releaseStage: import.meta.env.MODE,
  plugins: [new BugsnagPluginVue()],
});

const bugsnagVue = Bugsnag.getPlugin("vue")!;

posthog.init(import.meta.env.PH_API_KEY, {
  api_host: "https://app.posthog.com",
});

const pinia = createPinia();

const app = createApp(App);
app.use(bugsnagVue);
app.use(router);
app.use(pinia);
app.mount("#app");
