<script setup lang="ts">
import { computed } from "vue";
import { AppData, useAppLauncher } from "ram-components";
import { IconBookmark, IconHistory } from "@tabler/icons-vue";

import ApplicationGroup from "./ApplicationGroup.vue";
import ApplicationCard from "./ApplicationCard.vue";

import getStartedSvg from "../assets/images/getStarted.svg";

const props = defineProps({
  recent: { type: Array<AppData>, default: [] },
  favorites: { type: Array<AppData>, default: [] },
});

const appLauncher = useAppLauncher();

const filteredSections = computed(() => {
  const sections = [
    { title: "Favourites", apps: props.favorites, icon: IconBookmark },
    {
      title: "Recently Used",
      apps: props.recent,
      icon: IconHistory,
    },
  ];

  return sections.filter((section) => section.apps.length > 0);
});

function launchApp(app: AppData) {
  appLauncher.launch(app);
}
</script>

<template>
  <div
    v-if="props.recent.length === 0 && props.favorites.length === 0"
    class="flex grow items-center justify-center"
  >
    <img :src="getStartedSvg" alt="Get Started" />
  </div>
  <div v-else class="space-y-5">
    <ApplicationGroup
      v-for="section in filteredSections"
      :title="section.title"
      :icon="section.icon"
    >
      <ApplicationCard
        v-for="(app, idx) in section.apps"
        :key="idx"
        :img="app.icon"
        :title="app.name"
        :description="app.description"
        :version="app.appVersion"
        :developer="app.developer"
        @launch="launchApp(app)"
      />
    </ApplicationGroup>
  </div>
</template>
