<script setup lang="ts">
import { ref, reactive, onBeforeMount } from "vue";
import { IconEye, IconEyeOff } from "@tabler/icons-vue";

const props = defineProps({
  default: String,
  digitCount: {
    type: Number,
    required: true,
  },
  showToggle: {
    type: Boolean,
    default: false,
  },
  errors: {
    type: Array<String>,
    default: [],
  },
});

const emit = defineEmits(["update", "update:otp", "clear"]);

const showOtp = ref(!props.showToggle);
const digits = reactive<(string | null)[]>([]);
const otpContainer = ref<HTMLDivElement | null>(null);

onBeforeMount(() => {
  for (let i = 0; i < props.digitCount; i++) {
    digits[i] = props.default?.charAt(i) || null;
  }
});

function isComplete() {
  return digits.every((el) => el !== null && el !== undefined);
}

function isEmpty() {
  return digits.every((el) => el === null || el === undefined);
}

function handlePaste(e: ClipboardEvent) {
  if (e.clipboardData) {
    const _otp = e.clipboardData.getData("text").trim().replaceAll(' ', '');
    if (typeof _otp === "string" && _otp.length === props.digitCount) {
      for (let i = 0; i < props.digitCount; i++) {
        digits[i] = _otp[i];
      }
      (
        otpContainer.value!.children[props.digitCount - 1] as HTMLInputElement
      ).focus();
      if (isComplete()) {
        emit("update:otp", digits.join(""));
      }
    }
  }
}

function handleInput(e: Event, idx: number) {
  const input = e.target as HTMLInputElement;
  digits[idx] = input.value;

  if (digits[idx] && idx < props.digitCount - 1) {
    (otpContainer.value!.children[idx + 1] as HTMLInputElement).focus();
  }

  if (isComplete()) {
    emit("update:otp", digits.join(""));
  }

  emit("update", digits.join(""));
  if (isEmpty()) {
    emit("clear");
  }
}


function handleKeyDown(e: KeyboardEvent, idx: number) {
  if (
    e.getModifierState("Alt") ||
    e.getModifierState("Control" || e.getModifierState("Meta")) ||
    e.getModifierState("OS")
  ) {
    return;
  }

  if (e.key !== "Tab" && e.key !== "ArrowRight" && e.key !== "ArrowLeft") {
    e.preventDefault();
  }

  if (e.key === "ArrowRight") {
    e.preventDefault();
    if (idx !== props.digitCount - 1) {
      (otpContainer.value!.children[idx + 1] as HTMLInputElement).focus();
    }
  } else if (e.key === "ArrowLeft") {
    e.preventDefault();
    if (idx !== 0) {
      (otpContainer.value!.children[idx - 1] as HTMLInputElement).focus();
    }
  }

  if (e.key === "Backspace") {
    if (idx != 0 && !digits[idx]) {
      (otpContainer.value!.children[idx - 1] as HTMLInputElement).focus();
    }
    digits[idx] = null;
  }

  if (new RegExp("^([0-9a-zA-Z])$").test(e.key)) {
    digits[idx] = e.key;
    if (idx !== props.digitCount - 1) {
      (otpContainer.value!.children[idx + 1] as HTMLInputElement).focus();
    }
    if (isComplete()) {
      emit("update:otp", digits.join(""));
    }
  }

  emit("update", digits.join(""));
  if (isEmpty()) {
    emit("clear");
  }
}
</script>
<template>
  <div>
    <div ref="otpContainer" class="flex gap-3" @paste="handlePaste">
      <input
        :type="showOtp ? 'text' : 'password'"
        class="w-12 rounded-lg p-1 text-center text-4xl"
        v-for="(el, ind) in digits"
        :key="ind"
        v-model="digits[ind]"
        :autofocus="ind === 0"
        maxlength="1"
        @input="e => handleInput(e, ind)"
        @paste.exact.stop.prevent="handlePaste"
        @keydown="handleKeyDown($event, ind)"
      />
      <button @click="showOtp = !showOtp" v-if="props.showToggle">
        <component :is="showOtp ? IconEyeOff : IconEye" class="h-6 w-6" />
      </button>
    </div>
    <div class="mt-3">
      <div v-for="(error, idx) in props.errors" :key="idx" class="text-ram-red">
        {{ error }}
      </div>
    </div>
  </div>
</template>
