import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useAuthStore } from "../stores/authStore";
import Home from "@/views/Home.vue";
import MFA from "@/views/MFA.vue";
import Auth0Callback from "@/views/Auth0Callback.vue";

const routes: Array<RouteRecordRaw> = [
  { path: "/", component: Home },
  { path: "/mfa", component: MFA },
  { path: "/auth0callback", component: Auth0Callback },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.path === "/auth0callback") {
    const authStore = useAuthStore();
    await authStore.handleAuth0Callback();
    router.replace("/mfa");
  }

  const regex = /\/apps\/\w+/;
  if (regex.test(to.fullPath)) {
    const url = to.fullPath.match(regex)?.[0] || "";
    window.location.href = `${window.location.origin}${url}`;
  }

  next();
});

export default router;
