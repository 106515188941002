<script setup lang="ts">
import { onMounted } from "vue";

onMounted(() => {
  console.log(`✨ RAM Unify v${import.meta.env.RAM_APP_VERSION}`);
});
</script>

<template>
  <div class="h-full w-full font-inter">
    <router-view />
  </div>
</template>
