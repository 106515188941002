<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import dayjs from "dayjs";

import { useAuthStore } from "../stores/authStore";

const authStore = useAuthStore();

const currentDateTime = ref(getCurrentDateTime());

const greeting = computed(() => {
  const hourNow = dayjs().hour();
  if (hourNow <= 12) {
    return "Morning";
  } else if (hourNow <= 18) {
    return "Afternoon";
  } else {
    return "Evening";
  }
});

onMounted(() => {
  setInterval(() => {
    currentDateTime.value = getCurrentDateTime();
  }, 5000);
});

function getCurrentDateTime() {
  return dayjs().format("D MMMM YYYY, hh:mm A");
}
</script>

<template>
  <div>
    <div class="text-xl font-medium">
      Good {{ greeting }}, {{ authStore.user.name }}
    </div>
    <div>{{ currentDateTime }}</div>
  </div>
</template>
