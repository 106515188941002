<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import { Spinner, useToaster } from "ram-components";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import OtpInput from "../components/OtpInput.vue";

import { useAuthStore } from "../stores/authStore";
import Services from "../services";

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);

const router = useRouter();
const authStore = useAuthStore();

const toaster = useToaster();

const isLoading = ref(true);
const isProcessing = ref(false);
const animateError = ref(false);
const otp = ref("");
const otpLength = ref(4);
const isExpired = ref(false);
const otpExpiry = ref(dayjs());
const curTime = ref(dayjs());
const otpAttempts = ref(0);
const otpErrors = ref<Array<String>>([]);

const expiryCountdown = computed(() => {
  const _duration = dayjs.duration(otpExpiry.value.diff(curTime.value));
  if (
    otpExpiry.value.isSameOrBefore(curTime.value) ||
    _duration.asMilliseconds() < 800
  ) {
    isExpired.value = true;
    return "";
  }
  let template = "";
  if (_duration.get("h")) {
    template += "HH[h]";
  }
  if (_duration.get("m")) {
    template += "mm[m]";
  }
  if (_duration.get("s")) {
    template += "ss[s]";
  }
  return _duration.format(template);
});

onMounted(async () => {
  if (!authStore.user.email) {
    authStore.refreshLogin();
  }

  hideChatWidget();

  if (authStore.mfaRetriggerLimit === null || authStore.mfaRetriggerLimit > 0) {
    const res = (await Services.sendOtp(authStore.user.email)).data.Result;

    if (!res.IsUserMFAEnabled) {
      authStore.setMFAComplete();
      router.replace("/");
    }
    if (authStore.mfaRetriggerLimit === null) {
      authStore.setMfaRetriggerLimit(res.RetriggerLimit);
    }
    otpLength.value = res.OTPLength;
    otpExpiry.value = dayjs.tz(res.OTPExpiryTime, "Africa/Johannesburg");
  } else {
    authStore.logout();
  }

  setInterval(() => {
    curTime.value = dayjs();
  }, 500);
  isLoading.value = false;
});

onBeforeUnmount(() => {
  // @ts-ignore
  window.Tawk_API.showWidget();
});

function hideChatWidget() {
  // @ts-ignore
  if (window.Tawk_API.hideWidget) {
    // @ts-ignore
    window.Tawk_API.hideWidget();
  } else {
    // @ts-ignore
    window.Tawk_API.onLoad = function () {
      // @ts-ignore
      window.Tawk_API.hideWidget();
    };
  }
}

async function handleOtpSubmit() {
  isProcessing.value = true;
  const res = (await Services.validateOtp(authStore.user.email, otp.value)).data
    .Result;

  if (res.includes("Validated Successfully")) {
    authStore.setMFAComplete();
    router.replace("/");
  } else {
    otpErrors.value = [res];
    animateError.value = true;
    setTimeout(() => {
      animateError.value = false;
    }, 1200);
  }
  otpAttempts.value += 1;
  isProcessing.value = false;
}

async function handleOtpResend() {
  if (authStore.mfaRetriggerLimit && authStore.mfaRetriggerLimit > 0) {
    isLoading.value = true;
    const res = (await Services.sendOtp(authStore.user.email)).data.Result;
    toaster.push({
      text: `Resent OTP to ${authStore.user.email}`,
      type: "success",
    });
    authStore.setMfaRetriggerLimit(authStore.mfaRetriggerLimit - 1);
    otpLength.value = res.OTPLength;
    otpExpiry.value = dayjs.tz(res.OTPExpiryTime, "Africa/Johannesburg");
    isExpired.value = false;
    handleOtpReset();
    otpAttempts.value = 0;
    isLoading.value = false;
  } else {
    authStore.logout();
  }
}

function handleOtpReset() {
  otp.value = "";
  otpErrors.value = [];
}
</script>
<template>
  <div class="h-full w-full flex items-center justify-center bg-black/40">
    <div
      class="px-5 py-8 rounded-lg border shadow flex items-center flex-col bg-white"
    >
      <img src="../assets/images/ram_logo.svg" class="h-16 mx-16 mb-8" />
      <div v-if="isLoading">
        <Spinner class="h-6 w-6" />
      </div>
      <div
        v-else-if="
          otpAttempts > 3 ||
          (authStore.mfaRetriggerLimit! === 0 && (isExpired || otpAttempts > 2))
        "
      >
        <div class="text-2xl">Oops!</div>
        <div class="text-gray-600">
          You've exceeded the number of retries.<br />
          Please re-login and try again!
        </div>
        <button
          @click="authStore.logout()"
          class="mt-5 whitespace-nowrap flex w-full items-center justify-center gap-3 rounded-lg px-4 py-2 bg-ram-red text-white hover:bg-ram-red/80 disabled:bg-ram-red/70"
        >
          <span>Ok</span>
        </button>
      </div>
      <div v-else>
        <div class="flex flex-col items-center">
          <div>
            <span class="text-gray-600"
              >Please enter the OTP sent to&nbsp;</span
            >
            <span class="font-medium">{{ authStore.user.email }}</span>
          </div>
          <div class="text-sm text-gray-600" v-if="!isExpired">
            Your OTP expires in: {{ expiryCountdown }}
          </div>
          <div class="text-sm text-gray-600" v-else>Your OTP has expired</div>
        </div>
        <div
          class="flex justify-center my-5"
          :class="{ 'animate-wiggle': animateError }"
        >
          <OtpInput
            :digitCount="otpLength"
            :errors="otpErrors"
            @clear="handleOtpReset"
            @update="otp = $event"
            @update:otp="
              otp = $event;
              handleOtpSubmit();
            "
          />
        </div>
        <div class="flex flex-col gap-3 justify-between">
          <button
            @click="handleOtpSubmit()"
            class="whitespace-nowrap flex w-full items-center justify-center gap-3 rounded-lg px-4 py-2 bg-ram-red text-white hover:bg-ram-red/80 disabled:bg-ram-red/70"
            :disabled="otp.length !== otpLength"
          >
            <Spinner class="h-5 w-5" v-if="isProcessing" />
            <span v-else>Submit</span>
          </button>
          <button
            v-if="
              authStore.mfaRetriggerLimit! > 0 &&
              (isExpired || otpAttempts >= 3)
            "
            @click="handleOtpResend"
            class="whitespace-nowrap flex w-full items-center justify-center gap-3 rounded-lg px-4 py-2 text-red-600 hover:underline"
          >
            Resend OTP
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
