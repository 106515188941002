import axios from "axios";

import { ApiResult } from "../models/ApiResult";
import { OtpResult } from "../models/OtpResult";

const mfaApiUrl = import.meta.env.RAM_MFA_API_URL;

const sendOtpEndpoint = "/SendOTP";
const validateOtpEndpoint = "/ValidateOTP";

export const AuthAPI = axios.create({
  baseURL: mfaApiUrl,
});

export default {
  sendOtp(email: string) {
    return AuthAPI.get<ApiResult<OtpResult>>(`${sendOtpEndpoint}/${email}`);
  },
  validateOtp(email: string, otp: string) {
    return AuthAPI.get<ApiResult<string>>(
      `${validateOtpEndpoint}/${email}/${otp}`
    );
  },
};
