<script setup lang="ts">
import { FunctionalComponent, PropType } from "vue";
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  icon: {
    type: Function as PropType<FunctionalComponent>,
    required: true,
  },
});
</script>

<template>
  <div>
    <div class="mb-3 flex items-center gap-3 text-base sm:text-lg md:text-xl">
      <component :is="props.icon" :size="24"></component>
      <span>{{ props.title }}</span>
    </div>
    <div class="grid grid-cols-1 gap-2 sm:grid-cols-2 md:grid-cols-3">
      <slot></slot>
    </div>
  </div>
</template>
