<script setup lang="ts">
import { ref, onMounted } from "vue";
import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import "/node_modules/flag-icons/css/flag-icons.min.css";

dayjs.extend(utc);
dayjs.extend(timezone);

interface CountryTime {
  code: string;
  time: string;
}

const countries = ref(getTimes());

onMounted(() => {
  setInterval(() => {
    countries.value = getTimes();
  }, 5000);
});

function getTimes(): CountryTime[] {
  return [
    { code: "us", time: dayjs.utc().tz("America/New_York").format("HH:mm") },
    { code: "gb", time: dayjs.utc().tz("Europe/London").format("HH:mm") },
    {
      code: "za",
      time: dayjs.utc().tz("Africa/Johannesburg").format("HH:mm"),
    },
    { code: "in", time: dayjs.utc().tz("Asia/Kolkata").format("HH:mm") },
    { code: "jp", time: dayjs.utc().tz("Asia/Tokyo").format("HH:mm") },
    { code: "au", time: dayjs.utc().tz("Australia/Sydney").format("HH:mm") },
  ];
}
</script>

<template>
  <footer class="space-y-4 bg-white">
    <div class="text-center">
      <span
        class="rounded-full px-2 py-1 font-rambla text-lg font-bold italic text-ram-red"
      >
        From A-to-B, seamlessly
      </span>
    </div>
    <div class="flex flex-wrap items-center justify-center gap-x-6 gap-y-3">
      <div
        v-for="(country, idx) in countries"
        :key="idx"
        class="flex items-center gap-2"
      >
        <span :class="`fi border fi-${country.code}`" />
        <span class="text-gray-500">{{ country.time }}</span>
      </div>
    </div>
    <div>
      <p class="text-center text-base text-gray-400">
        <span class="inline-block">
          &copy; 2023 RAM Hand-To-Hand Couriers.&nbsp;
        </span>
        <span class="inline-block">All rights reserved.</span>
      </p>
    </div>
  </footer>
</template>
