<script setup lang="ts">
const props = defineProps({
  img: { type: String, required: true },
  title: { type: String, required: true },
  description: { type: String, required: true },
  version: { type: String, required: true },
  developer: { type: String, required: true },
});

const emit = defineEmits(["launch"]);

const formattedImgUrl = window.location.host.includes("localhost")
  ? `${import.meta.env.RAM_UAT_BASE}${props.img}`
  : props.img;
</script>

<template>
  <div class="flex rounded-lg border bg-white shadow">
    <button
      class="flex grow items-center hover:bg-gray-100"
      @click="emit('launch')"
    >
      <div class="flex h-full flex-none items-center p-1 sm:p-3">
        <img :src="formattedImgUrl" alt="" class="h-10 w-10 sm:h-12 sm:w-12" />
      </div>
      <div class="flex h-full grow flex-col items-start p-1 sm:p-3">
        <div class="text-sm font-medium sm:text-base">{{ props.title }}</div>
        <p class="line-clamp-3 grow text-left text-xs">
          {{ props.description }}
        </p>
      </div>
    </button>
  </div>
</template>
